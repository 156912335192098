import 'moment-timezone';
import Router from './components/router'
import { ThemeProvider, StyledEngineProvider, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { AlertPopupProvider } from './contexts/AlertPopupContext';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';


import { SnackbarProvider } from 'notistack';
import { useMemo } from 'react';
import useStore from './store/useStore';
import Notifier from '@components/other/Notifier';
import Loading from '@components/other/Loading';


export const SHOW_CANVAS_ID = false
export const DEMO_COMPANY_ID = "8c76b40d-2c27-4bca-b0c4-b78b71a2eb30"


// handle case when two active tabs have two different companies
// and one of them opens a link in new tab
// new tab should have the same company as tab from which it was opened
window.addEventListener('focus', () => {
  const currentSessionStorageCompanyId = sessionStorage.getItem('currentcompany')
  if (currentSessionStorageCompanyId) {
    localStorage.setItem('currentcompany', currentSessionStorageCompanyId)
  }
});


function App() {

  const darkMode = useStore(store => store.darkMode)
  let theme = useMemo(() => responsiveFontSizes(
    createTheme({
      typography: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
      },
      palette: {
        mode: darkMode ? 'dark' : 'light',
        // text: {}, // Keep this if you have custom text colors
        primary: {
          main: darkMode ? '#eceef8' : '#7743A3',
        },
        secondary: {
          main: '#F4263E',
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'capitalize',
            },
          },
        },
        MuiTextField: {
          defaultProps: {
            variant: 'standard',
          },
        },
      },
    })
  ), [darkMode]);

  return <>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Loading />
        <CssBaseline />


        <SnackbarProvider
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top'
          }}
          maxSnack={3}
        >
          <Notifier />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AlertPopupProvider>
              <div className="App">
                <Router />
              </div>
            </AlertPopupProvider>
          </LocalizationProvider>
        </SnackbarProvider>


      </ThemeProvider>
    </StyledEngineProvider>
  </>;
}

export default App;

